import React from 'react';

import Layout from '../components/layout';


class IndexPage extends React.Component {

  render() {

    return (
      <Layout>
        <section className="section-home">

          {/*
          "We're obsessed with providing a terrific experience. If not, we'll make it right - guaranteed."
          */}

          <div id="root">
            <div className="ae af">


              <main id="main">
                <div className="wcb3-billboard-cta b5 b6 b7 b8 b9 ba bb ae bx be jg bg bh bi bj c0 bl ji bn c2 bp jk br">
                  <div className="bj bs bt">
                    <div className="ci bt bs lc ld le cm lf lg lh li lj lk ll lm ln lo lp lq">
                      <div className="mp mq mr ms mt">
                        <div className="al bi bj k9 o6">
                          <div className="an m1 m2 hq c4">
                            <div className="af bi">
                              <div className="bv af j3 bj">
                                <img src="images/D_Homepage_Header2x.webp" className="cq j4 af an j5 se bj"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mu mk mv mw mx my mz">
                        <h1 className="o7 o8 o9 oa ob oc gs dd gt gu fx gv gw">Move the way you want</h1></div>
                      <div className="n0 jt n1 my">
                        <div className="bv bw af bj">
                          <div className="pw px py">
                            <h2 className="gm gn go gp gq gr gs dd gt gu fx gv gw">Drive</h2></div>
                          <div className="p2 pz q0">
                            <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">Drive when you want. Find
                              opportunities around you.<br/><a className="cmln__link" href="https://web.bellme.xyz?appId=lcc">Learn more</a></p>
                            </div>
                          </div>
                          <div className="ql"><a href="https://web.bellme.xyz?appId=lcc" target="_self" aria-label="" className="ga gb gc gd ge gf gg gh gi gj">
                            <div className="gt gu gb rw al rx bu hg e8 ic du ry gd">Sign up to drive
                              <div className="s0 s1 s2 pe i7 i8">
                                <svg aria-hidden="true" focusable="false" width="1em" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                  <path fillRule="evenodd" clipRule="evenodd"
                                        d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                </svg>
                              </div>
                            </div>
                          </a></div>
                        </div>
                      </div>
                      <div className="n2 jt n1 my">
                        <div className="n0 jt n1 my">
                          <div className="bv bw af bj">
                            <div className="pw px py"><h2 className="gm gn go gp gq gr gs dd gt gu fx gv gw">Ride</h2></div>
                            <div className="p2 pz q0">
                              <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">Tap your phone. Get where
                                you're headed.<br/><a className="cmln__link" href="https://web.bellme.xyz?appId=ride">Learn more</a></p>
                              </div>
                            </div>
                            <div className="ql"><a href="https://web.bellme.xyz?appId=ride" target="_self" aria-label="" className="ga gb gc gd ge gf gg gh gi gj">
                              <div className="gt gu gb rw al s3 ri hg e8 ic du s4 gd r4">Sign up to ride
                                <div className="s0 s1 s2 pe i7 i8">
                                  <svg aria-hidden="true" focusable="false" width="1em" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                  </svg>
                                </div>
                              </div>
                            </a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wcb3-banner-cta b5 b6 b7 b8 b9 ba bb ae jf be jg bg bh bi bj jh bl ji bn jj bp jk br">
                  <div className="bj bs bt"><a href="https://www.uber.com/en-in/fare-estimate/"
                                               className="bi cq fe lr gb ae ls gs lt lu al ih if lv lw lx ly lz m0">
                    <div className="af bi">
                      <div className="bv af j3 bj">
                        <img src="images/map-D2x_v2.webp" className="cq j4 af an j5 se bj"/>
                      </div>
                    </div>
                    <div className="an m1 c4 bj af bv e8">
                      <div className="bv ng dz nh ae ni">
                        <div className="ae bv e8 nh od oe of bn og oh oi br">
                          <div className="ir hc nv nu"><h2 className="qx qy qz r0 r1 r2 gs dd gt gu fx gv gw">Where to?</h2></div>
                          <div className="dt du fe hd pp">Get a price estimate</div>
                        </div>
                        <div className="ae oj bv e8 j7 ok ol i8 om on oo gz op oq">
                          <div className="pq pr ps j4 bh">
                            <svg aria-hidden="true" focusable="false" width="1em" height="1em" fill="currentColor" viewBox="0 0 36 36">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a></div>
                </div>


                <div className="wcb3-bullets al">
                  <div className="b5 b6 b7 b8 b9 ba bb ae jf be jg bg bh bi bj jh bl ji bn jj bp jk br">
                    <div className="bj bs bt">
                      <div>
                        <div className="ci nw di nx ny er nz o0 o1 o2">
                          <div className="cq af"></div>
                          <div className="cq af">
                            <div className="bj">
                              <div className="nd ne nf"><h2 className="qq qr om qs gq qt gs dd gt gu fx gv gw">Beyond ridesharing</h2></div>
                            </div>
                          </div>
                        </div>
                        <div className="n3 n4 n5">
                          <div role="region" aria-label="false" className="mt ou ov">
                            <ul className="q1 j4 gv am gs ci q2 q3 q4 q5 q6 q7 q8 q9 qa qb qc qd qe qf qg qh qi qj qk">
                              <li className="qp">
                                <div className="ci p5 af nx cn er p8 ro o1 rp">
                                  <div className="cq af">
                                    <div className="al bi bj k9 s5">
                                      <div className="an m1 m2 hq c4">
                                        <div className="af bi">
                                          <div className="bv af j3 bj">
                                            <img src="images/uber_eats_v2.svg" className="cq j4 af an j5 se bj"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cq af">
                                    <div className="bv bw af hj">
                                      <div className="pp rq p4"><h3 className="s6 s7 s8 s9 ii sa gs dd gt gu fx gv gw">Uber Eats</h3></div>
                                      <div className="pp rq p4">
                                        <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">Delicious eats,
                                          delivered easy</p>
                                        </div>
                                      </div>
                                      <div className="ql"><a href="https://www.ubereats.com/" target="_self" aria-label="Learn more about Uber Eats"
                                                             className="ga gb gc gd ge gf gg gh gi gj">
                                        <div className="gt gu gb dt du ri hg e8">
                                          <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                                          <div className="i6 i7 i8">
                                            <b aria-hidden="true">›</b></div>
                                        </div>
                                      </a></div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="qp">
                                <div className="ci p5 af nx cn er p8 ro o1 rp">
                                  <div className="cq af">
                                    <div className="al bi bj k9 s5">
                                      <div className="an m1 m2 hq c4">
                                        <div className="af bi">
                                          <div className="bv af j3 bj">
                                            <img src="images/uber_4Business.svg" className="cq j4 af an j5 se bj"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cq af">
                                    <div className="bv bw af hj">
                                      <div className="pp rq p4"><h3 className="s6 s7 s8 s9 ii sa gs dd gt gu fx gv gw">Uber for Business</h3></div>
                                      <div className="pp rq p4">
                                        <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">How teams help
                                          move
                                          people and guests</p>
                                        </div>
                                      </div>
                                      <div className="ql"><a href="https://www.uber.com/business/" target="_self"
                                                             aria-label="Learn more about Uber for Business"
                                                             className="ga gb gc gd ge gf gg gh gi gj">
                                        <div className="gt gu gb dt du ri hg e8">
                                          <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                                          <div className="i6 i7 i8"><b aria-hidden="true">›</b></div>
                                        </div>
                                      </a></div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="qp">
                                <div className="ci p5 af nx cn er p8 ro o1 rp">
                                  <div className="cq af">
                                    <div className="al bi bj k9 s5">
                                      <div className="an m1 m2 hq c4">
                                        <div className="af bi">
                                          <div className="bv af j3 bj">
                                            <img src="images/uber_jump.svg" className="cq j4 af an j5 se bj"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cq af">
                                    <div className="bv bw af hj">
                                      <div className="pp rq p4"><h3 className="s6 s7 s8 s9 ii sa gs dd gt gu fx gv gw">JUMP Bikes</h3></div>
                                      <div className="pp rq p4">
                                        <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">Dockless bikes,
                                          featuring e-assist</p>
                                        </div>
                                      </div>
                                      <div className="ql"><a href="https://jumpbikes.com/" target="_self" aria-label="Learn more about JUMP Bikes"
                                                             className="ga gb gc gd ge gf gg gh gi gj">
                                        <div className="gt gu gb dt du ri hg e8">
                                          <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                                          <div className="i6 i7 i8"><b aria-hidden="true">›</b></div>
                                        </div>
                                      </a></div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="qp">
                                <div className="ci p5 af nx cn er p8 ro o1 rp">
                                  <div className="cq af">
                                    <div className="al bi bj k9 s5">
                                      <div className="an m1 m2 hq c4">
                                        <div className="af bi">
                                          <div className="bv af j3 bj">
                                            <img src="images/uber_freight.svg" className="cq j4 af an j5 se bj"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cq af">
                                    <div className="bv bw af hj">
                                      <div className="pp rq p4"><h3 className="s6 s7 s8 s9 ii sa gs dd gt gu fx gv gw">Uber Freight</h3></div>
                                      <div className="pp rq p4">
                                        <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">Freedom to haul
                                          the
                                          way you want</p>
                                        </div>
                                      </div>
                                      <div className="ql"><a href="https://www.uberfreight.com/" target="_self" aria-label="Learn more about Uber Freight"
                                                             className="ga gb gc gd ge gf gg gh gi gj" tabIndex="-1">
                                        <div className="gt gu gb dt du ri hg e8">
                                          <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                                          <div className="i6 i7 i8"><b aria-hidden="true">›</b></div>
                                        </div>
                                      </a></div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="qp">
                                <div className="ci p5 af nx cn er p8 ro o1 rp">
                                  <div className="cq af">
                                    <div className="al bi bj k9 s5">
                                      <div className="an m1 m2 hq c4">
                                        <div className="af bi">
                                          <div className="bv af j3 bj">
                                            <img src="images/uber_elevate.svg" className="cq j4 af an j5 se bj"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cq af">
                                    <div className="bv bw af hj">
                                      <div className="pp rq p4"><h3 className="s6 s7 s8 s9 ii sa gs dd gt gu fx gv gw">Uber Air</h3></div>
                                      <div className="pp rq p4">
                                        <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">The future of
                                          urban
                                          aerial rides</p>
                                        </div>
                                      </div>
                                      <div className="ql"><a href="https://www.uber.com/info/elevate/" target="_self" aria-label="Learn more about Uber Air"
                                                             className="ga gb gc gd ge gf gg gh gi gj" tabIndex="-1">
                                        <div className="gt gu gb dt du ri hg e8">
                                          <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                                          <div className="i6 i7 i8"><b aria-hidden="true">›</b></div>
                                        </div>
                                      </a></div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li role="presentation" className="qp"></li>
                            </ul>
                          </div>
                          <div className="bv dy e8 ow ox oy oz p0">
                            <ul aria-hidden="true" className="ci fo e8 q1 j4 qm gv am jv n8">
                              <li>
                                <button disabled="" className="r3 r4 r5 gv am r6 r7 j4 gs i9 lr r8 r9 if ra"></button>
                              </li>
                              <li>
                                <button className="rb r4 r5 gv am r6 r7 j4 gs i9 lr r8 r9 if ra"></button>
                              </li>
                            </ul>
                            <ul aria-label="false" className="ci fo q1 j4 qm gv am">
                              <li>
                                <button aria-label="Previous" disabled="" className="r3 ag dd am j4 gs i9 lr rc ih if rd re">
                                  <svg aria-hidden="true" focusable="false" width="14" height="24" fill="currentColor" viewBox="0 0 15 24">
                                    <path d="M.9 24l8.7-11.9L.8 0h5.1l8.9 12-8.9 12z"></path>
                                  </svg>
                                </button>
                              </li>
                              <li>
                                <button aria-label="Next" className="r3 ag dd am j4 gs i9 lr ih if rd re">
                                  <svg aria-hidden="true" focusable="false" width="14" height="24" fill="currentColor" viewBox="0 0 15 24">
                                    <path d="M.9 24l8.7-11.9L.8 0h5.1l8.9 12-8.9 12z"></path>
                                  </svg>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wcb3-content-highlight b5 b6 b7 b8 b9 ba bb ae jf be jg bg bh bi bj jh bl ji bn jj bp jk br">
                  <div className="bj bs bt">
                    <div className="im e8 ci ck ea cj di ec ed d9 n6 ef eg eh ei cn">
                      <div className="nj nk al el nl nm nn no np">
                        <div className="hj">
                          <div className="p2 p3 p4"><h2 className="qq qr om qs gq qt gs dd gt gu fx gv gw">Your safety is always a top priority</h2></div>
                          <div className="p2 p3 p4">
                            <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">We’re committed to helping
                              riders and drivers get where they want to go with confidence.</p>
                            </div>
                          </div>
                          <a href="https://www.uber.com/en-in/safety/" target="_self" aria-label="Learn more about safety"
                             className="ga gb gc gd ge gf gg gh gi gj">
                            <div className="gt gu gb dt du ri hg e8">
                              <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                              <div className="i6 i7 i8"><b aria-hidden="true">›</b></div>
                            </div>
                          </a></div>
                      </div>
                      <div className="nq fh af nr ir ns bi bj nt no np nu nv">
                        <div className="al bi bj k9 p1">
                          <div className="an m1 m2 hq c4">
                            <div className="af bi">
                              <div className="cq di j3 bj">
                                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="cq j4 di an j5 j6 bj"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wcb3-banner-campaign bj bv j7 j8 cc j9 ed bi ja bs jb jc jd je">
                  <div className="jl b7 bj bt cq ck jm jn bi am af al jo jp jq jr js jt ju jv jw jx jy jz k0 k1 k2">
                    <div
                      className="el fh fi k3 k4 k5 bj j8 ci k6 k7 k8 b7 fe al k9 ka kb kc kd ke kf kg kh ki kj jq kk kl jp km kn ko kp fm kq fv kr ks kt ku kv jx fa kw jw kx ky kz">
                      <div className="an m1 c4 m2 hq m3 ci k6 kk kl fa kw">
                        <div className="af bi">
                          <div className="cq di j3 bj">

                            <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" className="cq j4 di an j5 j6 bj"/>
                          </div>
                        </div>
                      </div>
                      <div className="an m1 c4 m2 hq k7 ci k6 m4 m5 m6 m7">
                        <div className="bs b7 bj bt ci ck n7 jn bi am af j9 n8 jp jq jr js jt ju jv jw jx jy jz k0 k1 k2">
                          <div
                            className="o3 em ma mb k4 e8 bj j8 ci k6 k7 am b7 fe j9 kb o4 kd mf mg js kh ki l7 jq kk kl jp kp o5 kq dr ds ks k0 ku la jx fa kw jw">
                            <div
                              className="m8 m9 ma mb k4 k5 or j8 ci k6 k7 am b7 fe j9 kb md me mf mg js kh ki kj mv kk kl jp kp mi mj dr ds ks k0 ku kv os fa kw jw">
                              <div className="bj">
                                <div className="p2 p3 rf"><h2 className="qq qr om qs gq qt gs dd gt gu fx gv gw">Built for drivers, with drivers</h2></div>
                                <div className="p2 p3 rf">
                                  <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">The new Driver app helps
                                    you earn smarter and supports you—like a partner—at every turn.</p>
                                  </div>
                                </div>
                                <a href="https://www.uber.com/en-in/drive/driver-app/" target="_self" aria-label="See features to help you earn smarter"
                                   className="ga gb gc gd ge gf gg gh gi gj">
                                  <div className="gt gu gb dt du ri hg e8">
                                    <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                                    <div className="i6 i7 i8"><b aria-hidden="true">›</b></div>
                                  </div>
                                </a></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="el em eu l0 k4 e8 bj l1 dl cb k7 l2 b7 fe j9 l3 kc kd l4 l5 l6 kh ki l7 jq kk kl jp l8 fm kq dx fl ks l9 ku la jx fa kw jw">
                      <div
                        className="m8 m9 ma mb k4 k5 hj j8 ci k6 k7 am b7 fe mc kb md me mf mg js kh ki kj mh kk kl jp kp mi mj dr ds ks k0 ku kv mk fa kw jw">
                        <div className="bj">
                          <div className="p2 p3 p4"><h2 className="qq qr om qs gq qt gs dd gt gu fx gv gw">Built for drivers, with drivers</h2></div>
                          <div className="p2 p3 p4">
                            <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">The new Driver app helps you
                              earn smarter and supports you—like a partner—at every turn.</p>
                            </div>
                          </div>
                          <a href="https://www.uber.com/en-in/drive/driver-app/" target="_self" aria-label="See features to help you earn smarter"
                             className="ga gb gc gd ge gf gg gh gi gj">
                            <div className="gt gu gb dt du ri hg e8">
                              <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                              <div className="i6 i7 i8"><b aria-hidden="true">›</b></div>
                            </div>
                          </a></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wcb3-bullets al">
                  <div className="b5 b6 b7 b8 b9 ba bb ae jf be jg bg bh bi bj jh bl ji bn jj bp jk br">
                    <div className="bj bs bt">
                      <div>
                        <div className="im ch ci n9 ea na di ec ed d9 nb ef eg eh ei nc">
                          <div className="ci p5 af nx p6 p7 p8 p9 pa pb">
                            <div className="cq af">
                              <div className="qu qv qw">
                                <div className="al bi bj k9 rj">
                                  <div className="an m1 m2 hq c4">
                                    <div className="af bi">
                                      <div className="cq di j3 bj">
                                        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                                             className="cq j4 di an j5 j6 bj"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="cq af">
                              <div className="bv bw af bj">
                                <div className="pp rq py"><h3 className="gm gn go gp gq gr gs dd gt gu fx gv gw">About us</h3></div>
                                <div className="pp rq py">
                                  <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">Our story, leadership,
                                    and
                                    where we’re going</p>
                                  </div>
                                </div>
                                <div className="ql"><a href="https://www.uber.com/en-in/about/" target="_self" aria-label="Learn more about the company"
                                                       className="ga gb gc gd ge gf gg gh gi gj">
                                  <div className="gt gu gb dt du ri hg e8">
                                    <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                                    <div className="i6 i7 i8"><b aria-hidden="true">›</b></div>
                                  </div>
                                </a></div>
                              </div>
                            </div>
                          </div>
                          <div className="ci p5 af nx p6 p7 p8 p9 pa pb">
                            <div className="cq af">
                              <div className="qu qv qw">
                                <div className="al bi bj k9 rj">
                                  <div className="an m1 m2 hq c4">
                                    <div className="af bi">
                                      <div className="cq di j3 bj">
                                        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                                             className="cq j4 di an j5 j6 bj"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="cq af">
                              <div className="bv bw af bj">
                                <div className="pp rq py"><h3 className="gm gn go gp gq gr gs dd gt gu fx gv gw">Newsroom</h3></div>
                                <div className="pp rq py">
                                  <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">Company news,
                                    partnerships, and app updates</p>
                                  </div>
                                </div>
                                <div className="ql"><a href="https://www.uber.com/en-in/newsroom/" target="_self" aria-label="Get the latest company news"
                                                       className="ga gb gc gd ge gf gg gh gi gj">
                                  <div className="gt gu gb dt du ri hg e8">
                                    <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                                    <div className="i6 i7 i8"><b aria-hidden="true">›</b></div>
                                  </div>
                                </a></div>
                              </div>
                            </div>
                          </div>
                          <div className="ci p5 af nx p6 p7 p8 p9 pa pb">
                            <div className="cq af">
                              <div className="qu qv qw">
                                <div className="al bi bj k9 rj">
                                  <div className="an m1 m2 hq c4">
                                    <div className="af bi">
                                      <div className="cq di j3 bj">
                                        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                                             className="cq j4 di an j5 j6 bj"/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="cq af">
                              <div className="bv bw af bj">
                                <div className="pp rq py"><h3 className="gm gn go gp gq gr gs dd gt gu fx gv gw">Community</h3></div>
                                <div className="pp rq py">
                                  <div className="fe b9 fw fx fy fz g0 g1 g2 g3 g4 g5 da g6 g7 g8 dc g9"><p className="cmln__paragraph">Connecting communities
                                    and
                                    strengthening cities</p>
                                  </div>
                                </div>
                                <div className="ql"><a href="https://www.uber.com/en-in/community/" target="_self" aria-label="How we&#39;re helping cities"
                                                       className="ga gb gc gd ge gf gg gh gi gj">
                                  <div className="gt gu gb dt du ri hg e8">
                                    <div className="bi hx hy hz i0 i1 i2 i3 i4 i5">Learn more</div>
                                    <div className="i6 i7 i8"><b aria-hidden="true">›</b></div>
                                  </div>
                                </a></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>






                <div className="wcb3-multilink-bold b5 b6 b7 b8 b9 ba bb ae jf be jg bg bh bi bj jh bl ji bn jj bp jk br">
                  <div className="bj bs bt">
                    <div className="ci ck cj jr lb">
                      <div className="el em fi ml kc mm lb mn"><a href="https://www.uber.com/signup/drive/" target="_self" aria-label=""
                                                                  className="ga gb gc gd jx k1 jq jt ot n1">
                        <div className="pt pu bf gb gs bi bj af bv dz b7 pv jk">
                          <div className="ci qn bj qo">
                            <div className="el rg"><h2 className="qx qy qz r0 r1 r2 gs dd gt gu fx gv gw">Sign up to drive</h2></div>
                            <div className="o3 rh">
                              <div className="rk al cq rl i8 rm rn">
                                <div className="rr rs bv rt pr ps ru rv dy">
                                  <div className="oj gy ru rz al dd">
                                    <svg aria-hidden="true" focusable="false" width="44px" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                      <path fillRule="evenodd" clipRule="evenodd"
                                            d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                    </svg>
                                  </div>
                                  <div className="oj gy ru rz al dd">
                                    <svg aria-hidden="true" focusable="false" width="44px" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                      <path fillRule="evenodd" clipRule="evenodd"
                                            d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a></div>
                      <div className="fg fh fi ml mo kd lb mn"><a href="https://get.uber.com/sign-up/" target="_self" aria-label=""
                                                                  className="ga gb gc gd jx k1 jq jt ot n1">
                        <div className="pt pu bf gb gs bi bj af bv dz b7 pv jk">
                          <div className="ci qn bj qo">
                            <div className="el rg"><h2 className="qx qy qz r0 r1 r2 gs dd gt gu fx gv gw">Sign up to ride</h2></div>
                            <div className="o3 rh">
                              <div className="rk al cq rl i8 rm rn">
                                <div className="rr rs bv rt pr ps ru rv dy">
                                  <div className="oj gy ru rz al dd">
                                    <svg aria-hidden="true" focusable="false" width="44px" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                      <path fillRule="evenodd" clipRule="evenodd"
                                            d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                    </svg>
                                  </div>
                                  <div className="oj gy ru rz al dd">
                                    <svg aria-hidden="true" focusable="false" width="44px" height="1em" fill="currentColor" viewBox="0 0 36 36">
                                      <path fillRule="evenodd" clipRule="evenodd"
                                            d="M18.2354 2H24.2711L36 18L24.2711 34H18.2354L28.1237 20.56H0V15.44H28.1237L18.2354 2Z" fill="currentColor"></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a></div>
                    </div>
                  </div>
                </div>
                <div className="wcb3-legal b5 b6 b7 b8 b9 ba bb ae jf be jg bg bh bi bj jh bl ji bn jj bp jk br">
                  <div className="bj bs bt">
                    <div className="hj">
                      <div className="nd ne nf">
                        <div className="fe b9 fw fx fy fz g0 g1 g2 pc pd g4 g5 pe g6 pf pg ph pi pj pk pl pm pn po"><p className="cmln__paragraph">Certain
                          requirements and features vary by country, region, and city.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>


            </div>
          </div>


        </section>
      </Layout>
    );
  }
}

export default IndexPage;
